<div mat-dialog-title>
  <div class="justify-between">
    <h1 class="fw-500">{{ title }}</h1>
    <app-button-save-exit (onClick)="save(true)" />
  </div>
</div>

<mat-dialog-content>
  <div class="mt-6" [formGroup]="form">
    <div class="d-flex gap-24 f-column">
      <div class="set-grid set-grid-2 stretch pt-6">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.date.touched && controls.date.invalid
          ">
          <app-roam-datepicker name="Request Date" formControlName="date" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        <div></div>
      </div>

      <div class="set-grid set-grid-2 stretch">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.propertyId.touched && controls.propertyId.invalid
          ">
          <app-roam-select
            placeholder="Association"
            formControlName="propertyId"
            [items]="opts.properties()"
            [searchable]="true" />
          <p class="roam-form-error-message">This field is required</p>
        </div>

        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.customerId.touched && controls.customerId.invalid
          ">
          <app-roam-select
            placeholder="Ownership Account"
            formControlName="customerId"
            [items]="opts.customers()"
            [searchable]="true" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>

      <div class="set-grid set-grid-2 stretch">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.startDate.touched && controls.startDate.invalid
          ">
          <app-roam-datepicker name="Start Date" formControlName="startDate" />
          <p class="roam-form-error-message">This field is required</p>
        </div>

        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.completionDate.touched && controls.completionDate.invalid
          ">
          <app-roam-datepicker
            name="Completion Date"
            formControlName="completionDate" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>

      <div>
        <h6 class="title font-16px">
          Select type of modification that you planning to do
        </h6>
        <div>
          <h6 class="tc-gray">Minor modifications</h6>
          <div class="card-select-radio mt-16">
            @if (!loaders.typesLoading()) {
              <app-roam-radio-card
                [options]="opts.minorTypes()"
                formControlName="categoryId" />
            } @else {
              <ng-container *ngTemplateOutlet="spinner"></ng-container>
            }
          </div>
        </div>
        <div>
          <h6 class="tc-gray mtb-16">Moderate sized modifications</h6>
          <div class="card-select-radio mt-16">
            @if (!loaders.typesLoading()) {
              <app-roam-radio-card
                [options]="opts.moderateTypes()"
                formControlName="categoryId" />
            } @else {
              <ng-container *ngTemplateOutlet="spinner"></ng-container>
            }
          </div>
        </div>
        <div>
          <h6 class="tc-gray mtb-16">Major modifications</h6>
          <div class="card-select-radio mt-16">
            @if (!loaders.typesLoading()) {
              <app-roam-radio-card
                [options]="opts.majorTypes()"
                formControlName="categoryId" />
            } @else {
              <ng-container *ngTemplateOutlet="spinner"></ng-container>
            }
          </div>
        </div>
      </div>

      <div class="set-grid set-grid-2 stretch">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.name.touched && controls.name.invalid
          ">
          <app-roam-input name="Project Name" formControlName="name" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        <div></div>
      </div>

      <div>
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="
            controls.description.touched && controls.description.invalid
          ">
          <app-roam-text-area
            name="Additional Information (Optional)"
            formControlName="description" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>

      <div class="sharing">
        <h6 class="title">Sharing</h6>
        <sharing-email />
      </div>

      <div>
        <h6 class="title">Attachments</h6>
        <div>
          <app-file-uploader
            containerText="Drag Here"
            [(data)]="attachments"
            [config]="modelConfig()" />
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="mat-dialog-roam-action pr-28">
  <button-cancel mat-dialog-close />
  <div class="d-flex gap-16">
    <app-roam-button label="Create" (onClick)="save()" />
  </div>
</mat-dialog-actions>

<ng-template #spinner>
  <div class="justify-center mtb-16">
    <mat-spinner diameter="50" />
  </div>
</ng-template>
